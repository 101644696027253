<template>
  <div class="green">
    <!-- 第一行 -->
    <div class="row">
      <div class="col">
        <div class="r1-c1-r1">
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-title">
                <div class="border-name">PM2.5</div>
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-2 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <div class="border-body-middle grow">
                <div class="block-wrap">
                  <div class="block-top">
                    <div>
                      <select v-model="currentDevicePm25">
                        <option
                          v-for="(item, index) in deviceList"
                          :key="index"
                          :value="index"
                        >
                          {{ item.deviceName }}
                        </option>
                      </select>
                    </div>
                    <div>单位: ug/m³</div>
                  </div>
                  <div class="block-content" id="echarts-r1-c1-r1"></div>
                </div>
              </div>
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="r1-c1-r2">
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-title">
                <div class="border-name">噪音</div>
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-2 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <div class="border-body-middle grow">
                <div class="block-wrap">
                  <div class="block-top">
                    <div>
                      <select v-model="currentDeviceNoise">
                        <option
                          v-for="(item, index) in deviceList"
                          :key="index"
                          :value="index"
                        >
                          {{ item.deviceName }}
                        </option>
                      </select>
                    </div>
                    <div>单位: db</div>
                  </div>
                  <div class="block-content" id="echarts-r1-c1-r2"></div>
                </div>
              </div>
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mid">
        <div class="r1-c2">
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-1 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <div class="border-body-middle grow mainWrap">
                <!--  -->
                <div id="glWrap">
                  <canvas id="webgl"></canvas>
                  <div
                    class="qipao"
                    v-for="(item, index) in bubblesList"
                    :key="index"
                    :style="`top: ${item.top}rem; left: ${item.left}rem; width: ${item.size}rem; height: ${item.size}rem; line-height: ${item.size}rem; font-size: ${item.fontSize}rem;`"
                  >
                    <span>{{ item.name }}</span>
                    <span>{{ item.value }}</span>
                  </div>
                </div>
                <div
                  class="deviceMenu"
                  @click="showMenu = !showMenu"
                  :class="[showMenu ? 'deviceMenuActive' : '']"
                >
                  设备列表
                </div>
                <transition name="main">
                  <div
                    class="deviceMenuWrap"
                    v-show="showMenu"
                    @mouseleave="showMenu = false"
                  >
                    <div
                      v-for="(item, index) in deviceList"
                      :key="index"
                      class="deviceMenuItem"
                      @click="setDevice(index)"
                    >
                      {{ item.deviceName }}
                    </div>
                  </div>
                </transition>
                <!--  -->
              </div>
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="r1-c3-r1">
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-title">
                <div class="border-name">PM10</div>
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-2 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <div class="border-body-middle grow">
                <div class="block-wrap">
                  <div class="block-top">
                    <div>
                      <select v-model="currentDevicePm10">
                        <option
                          v-for="(item, index) in deviceList"
                          :key="index"
                          :value="index"
                        >
                          {{ item.deviceName }}
                        </option>
                      </select>
                    </div>
                    <div>单位: ug/m³</div>
                  </div>
                  <div class="block-content" id="echarts-r1-c3-r1"></div>
                </div>
              </div>
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="r1-c3-r2">
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-title">
                <div class="border-name">温度</div>
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-2 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <div class="border-body-middle grow">
                <div class="block-wrap">
                  <div class="block-top">
                    <div>
                      <select v-model="currentDeviceTemperature">
                        <option
                          v-for="(item, index) in deviceList"
                          :key="index"
                          :value="index"
                        >
                          {{ item.deviceName }}
                        </option>
                      </select>
                    </div>
                    <div>单位: ℃</div>
                  </div>
                  <div class="block-content" id="echarts-r1-c3-r2"></div>
                </div>
              </div>
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="row">
      <!-- 项目进度 -->
      <div class="r2-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">风力</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div class="block-wrap">
                <div class="block-top">
                  <div>
                    <select v-model="currentDeviceWind">
                      <option
                        v-for="(item, index) in deviceList"
                        :key="index"
                        :value="index"
                      >
                        {{ item.deviceName }}
                      </option>
                    </select>
                  </div>
                  <div>单位: 级</div>
                </div>
                <div class="block-content" id="echarts-r2-c1"></div>
              </div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r2-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">告警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div class="block-wrap">
                <div class="block-top">
                  <div>
                    <select v-model="currentDeviceAlarm">
                      <option :value="''">全部</option>
                      <option
                        v-for="(item, index) in deviceList"
                        :key="index"
                        :value="item.serialNumber"
                      >
                        {{ item.deviceName }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <select v-model="currentDeviceAlarmDate">
                      <option
                        v-for="(item, index) in dateList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="block-content">
                  <div class="alarm-wrap" v-if="dustAlarmData.length">
                    <div
                      class="alarm-item"
                      v-for="(item, index) in dustAlarmData"
                      :key="index"
                    >
                      <div>{{ item.deviceName }}</div>
                      <div>{{ item.alarmMsg }}</div>
                      <div>{{ item.alarmDate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r2-c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">种类报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <!--  -->
              <div class="block-wrap">
                <div class="block-top">
                  <div>
                    <select v-model="currentDeviceAlarmType">
                      <option :value="''">全部</option>
                      <option
                        v-for="(item, index) in deviceList"
                        :key="index"
                        :value="item.serialNumber"
                      >
                        {{ item.deviceName }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <select v-model="currentDeviceAlarmTypeDate">
                      <option
                        v-for="(item, index) in dateList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div id="echarts-r2-c3" class="block-content"></div>
              </div>
              <!--  -->
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r2-c4">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">湿度</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <div class="block-wrap">
                <div class="block-top">
                  <div>
                    <select v-model="currentDeviceWet">
                      <option
                        v-for="(item, index) in deviceList"
                        :key="index"
                        :value="index"
                      >
                        {{ item.deviceName }}
                      </option>
                    </select>
                  </div>
                  <div>单位: %RH</div>
                </div>
                <div class="block-content" id="echarts-r2-c4"></div>
              </div>
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 475 / @Width;
}
.row-height-2 {
  height: 190 / @Width;
}

.green {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20 / @Width;
  }
  .r2-c1,
  .r2-c4,
  .r1-c1-r1,
  .r1-c1-r2,
  .r1-c3-r1,
  .r1-c3-r2 {
    width: 460 / @Width;
    height: 265 / @Width;
  }
  .r1-c1-r1,
  .r1-c3-r1 {
    margin-bottom: 20 / @Width;
  }

  .r2-c2,
  .r2-c3 {
    width: 425 / @Width;
    height: 265 / @Width;
  }
  .r1-c2 {
    width: 875 / @Width;
    height: 550 / @Width;
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}

#glWrap,
#webgl {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .qipao {
    position: absolute;
    text-align: center;
    border-radius: 50%;
    font-weight: 700;
    color: #ffffff;
    border: 2px solid #00e4ff;
    background-color: rgba(0, 228, 255, 0.2);
    box-shadow: 0 0 10 / @Width #00e4ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.1 !important;
  }
}
.mainWrap {
  position: relative;
}
.deviceMenu {
  position: absolute;
  z-index: 999;
  width: 200 / @Width;
  height: 50 / @Width;
  font-size: 20 / @Width;
  line-height: 50 / @Width;
  bottom: 0 / @Width;
  text-align: center;
  left: (875 - 200 - 50) / 2 / @Width;
  border-radius: 10 / @Width;

  font-weight: 700;
  color: #ffffff;
  background-color: rgba(0, 228, 255, 0.2);
  border: 2px solid #00e4ff;
  box-shadow: 0 0 10 / @Width #00e4ff;
}
.deviceMenuActive {
  background-color: rgba(15, 16, 66, 0.9);
}
.deviceMenuWrap {
  position: absolute;
  width: 450 / @Width;
  height: 360 / @Width;
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: rgba(15, 16, 66, 0.9);
  bottom: 70 / @Width;
  text-align: center;
  left: (875 - 450 - 50) / 2 / @Width;
  border-radius: 10 / @Width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  border: 2px solid #00e4ff;
  box-shadow: 0 0 10 / @Width #00e4ff;
}
.deviceMenuItem {
  font-size: 16 / @Width;
  padding: 10 / @Width;
  color: #fff;
  font-weight: 700;
}
.block-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .block-top {
    position: absolute;
    width: 100%;
    top: -35 / @Width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00e4ff;
    font-size: 12 / @Width;
    line-height: 26 / @Width;
    div {
      margin-right: 15 / @Width;
    }
  }
  .block-content {
    width: 100%;
    height: 100%;
  }
}
select {
  width: 80 / @Width;
  border: 1px solid #00e4ff;
  height: 26 / @Width;
  border-radius: 15 / @Width;
  background-color: rgba(0, 0, 0, 0);
  color: #00e4ff;
  font-size: 12 / @Width;
  line-height: 26 / @Width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.alarm-wrap {
  width: 100%;
  height: 100%;
  overflow: scroll;
  .alarm-item {
    padding: 10 / @Width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(rgba(26, 62, 184, 0.8), rgba(26, 62, 184, 0.1));
    margin-bottom: 20 / @Width;
    border: 1px solid #00e4ff;
    color: #00e4ff;
  }
  .alarm-item:first-child {
    margin-top: 10 / @Width;
  }
  .alarm-item:last-child {
    margin-bottom: 0;
  }
}
.temp {
  color: rgb(115, 31, 224);
}
</style>

<script>
export default {
  name: "Green",
  data() {
    return {
      bubbleFloatTimer: null,
      bubblesList: [
        {
          name: "PM2.5",
          value: null,
          top: 40 / 192,
          left: 40 / 192,
          size: 130 / 192,
          fontSize: 24 / 192,
        },
        {
          name: "大气压",
          value: null,
          top: 180 / 192,
          left: 30 / 192,
          size: 85 / 192,
          fontSize: 20 / 192,
        },
        {
          name: "风向",
          value: null,
          top: 270 / 192,
          left: 130 / 192,
          size: 75 / 192,
          fontSize: 18 / 192,
        },
        {
          name: "风力",
          value: null,
          top: 155 / 192,
          left: 190 / 192,
          size: 85 / 192,
          fontSize: 22 / 192,
        },
        {
          name: "PM10",
          value: null,
          top: 155 / 192,
          left: 336 / 192,
          size: 125 / 192,
          fontSize: 26 / 192,
        },
        {
          name: "悬浮微粒",
          value: null,
          top: 123 / 192,
          left: 480 / 192,
          size: 76 / 192,
          fontSize: 16 / 192,
        },
        {
          name: "噪音",
          value: null,
          top: 251 / 192,
          left: 540 / 192,
          size: 98 / 192,
          fontSize: 22 / 192,
        },
        {
          name: "湿度",
          value: null,
          top: 100 / 192,
          left: 603 / 192,
          size: 125 / 192,
          fontSize: 28 / 192,
        },
        {
          name: "温度",
          value: null,
          top: 28 / 192,
          left: 715 / 192,
          size: 98 / 192,
          fontSize: 24 / 192,
        },
        {
          name: "风速",
          value: null,
          top: 225 / 192,
          left: 725 / 192,
          size: 76 / 192,
          fontSize: 20 / 192,
        },
      ],
      showMenu: false,
      dustListData: [],
      dustAlarmData: [],
      dustAlarmTypeData: [],
      deviceList: [
        {
          deviceName: "",
          serialNumber: "",
        },
      ],
      currentDevicePm25: 0,
      currentDevicePm10: 0,
      currentDeviceNoise: 0,
      currentDeviceTemperature: 0,
      currentDeviceWind: 0,
      currentDeviceWet: 0,
      currentDeviceAlarm: "",
      currentDeviceAlarmDate: this.getDay(0),
      currentDeviceAlarmType: "",
      currentDeviceAlarmTypeDate: this.getDay(0),
      echarts: {
        r1c1r1: null,
        r1c1r2: null,
        r1c3r1: null,
        r1c3r2: null,
        r2c1: null,
        r2c4: null,
      },
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    dateList: function () {
      var arr = [];
      for (var i = 0; i < 7; i++) {
        arr.push(this.getDay(i * -1));
      }
      return arr;
    },
    currentPm25Data: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "pm25") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDevicePm25];
    },
    currentPm10Data: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "pm10") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDevicePm10];
    },
    currentNoiseData: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "噪音") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDeviceNoise];
    },
    currentTemperatureData: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "温度") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDeviceTemperature];
    },
    currentWindData: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "风力") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDeviceWind];
    },
    currentWetData: function () {
      var obj;
      this.dustListData.forEach((item) => {
        if (item.itemName == "湿度") {
          obj = item;
        }
      });
      return obj.itemList[this.currentDeviceWet];
    },
    currentAlarmData: function () {
      var obj = [];
      // this.dustAlarmData.forEach((item, index) => {
      //   if (item.itemName == "告警信息") {
      //     obj = item.itemList;
      //   }
      // });
      return obj;
    },
  },
  watch: {
    currentProject(newVal, oldVal) {
      console.log("watch currentProject=>", newVal);
      this.onGetDustListData(); // 获取环境数据
      this.onGetDustAlarmData(); // 获取报警数据
      this.onGetDustAlarmTypeData(); // 获取报警种类数据
      this.getDustAvgList(); // 获取当前项目所有设备环境因子的平均值
    },
    dustListData: {
      handler: function (newVal, oldVal) {
        var arr = [];
        newVal[0].itemList.forEach((item) => {
          arr.push({
            deviceName: item.deviceName,
            serialNumber: item.serialNumber,
          });
        });
        this.deviceList = arr;
      },
      deep: true,
    },
    currentDevicePm25: function () {
      this.echartsR1C1R1Init(); // 绘制 echarts-r1-c1-r1 (pm25) 折线图
    },
    currentDevicePm10: function () {
      this.echartsR1C3R1Init(); // 绘制 echarts-r1-c3-r1 (pm10) 折线图
    },
    currentDeviceNoise: function () {
      this.echartsR1C1R2Init(); // 绘制 echarts-r1-c1-r2 (噪音) 柱状图
    },
    currentDeviceTemperature: function () {
      this.echartsR1C3R2Init(); // 绘制 echarts-r1-c3-r2 (温度) 柱状图
    },
    currentDeviceWind: function () {
      this.echartsR2C1Init(); // 绘制 echarts-r2-c1 (风力) 柱状图
    },
    currentDeviceWet: function () {
      this.echartsR2C4Init(); // 绘制 echarts-r2-c4 (湿度) 柱状图
    },
    currentDeviceAlarm: function () {
      this.onGetDustAlarmData(); // 获取报警数据
    },
    currentDeviceAlarmDate: function () {
      this.onGetDustAlarmData(); // 获取报警数据
    },
    currentDeviceAlarmType: function () {
      this.onGetDustAlarmTypeData(); // 获取报警种类数据
    },
    currentDeviceAlarmTypeDate: function () {
      this.onGetDustAlarmTypeData(); // 获取报警种类数据
    },
  },
  mounted() {
    this.webGlInit(); // 绘制 webgl 3D 粒子效果
    this.bubbleFloat(); // 开始气泡漂浮效果
    this.onGetDustListData(); // 获取环境数据
    this.onGetDustAlarmData(); // 获取报警数据
    this.onGetDustAlarmTypeData(); // 获取报警种类数据
    this.getDustAvgList(); // 获取当前项目所有设备环境因子的平均值
  },
  beforeDestroy() {},
  methods: {
    // 绘制 webgl 3D 粒子效果
    webGlInit: function () {
      var canvas = document.getElementById("webgl");
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      var gl = canvas.getContext("webgl");

      var vertexShaderSource =
        document.getElementById("vertexShader").innerText;

      var fragShaderSource =
        document.getElementById("fragmentShader").innerText;

      var program = initShader(gl, vertexShaderSource, fragShaderSource);

      var aposLocation = gl.getAttribLocation(program, "position");
      var scale = gl.getAttribLocation(program, "scale");

      var modelViewMatrixLoc = gl.getUniformLocation(
        program,
        "modelViewMatrix"
      );
      var projectionMatrixLoc = gl.getUniformLocation(
        program,
        "projectionMatrix"
      );

      var SEPARATION = 100,
        AMOUNTX = 50,
        AMOUNTY = 50;
      var numParticles = AMOUNTX * AMOUNTY;

      var positions = new Float32Array(numParticles * 3);
      var scales = new Float32Array(numParticles);

      var i = 0,
        j = 0;

      for (var ix = 0; ix < AMOUNTX; ix++) {
        for (var iy = 0; iy < AMOUNTY; iy++) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2; // x
          positions[i + 1] = 0; // y
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2; // z
          scales[j] = 1;
          i += 3;
          j++;
        }
      }

      var colorBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, scales, gl.STATIC_DRAW);
      gl.vertexAttribPointer(scale, 1, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(scale);

      var buffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
      gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);
      gl.vertexAttribPointer(aposLocation, 3, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(aposLocation);

      gl.enable(gl.DEPTH_TEST);

      var width = window.innerWidth; //
      var height = window.innerHeight; //
      var camera = new THREE.PerspectiveCamera(60, width / height, 1, 10000);
      camera.position.set(200, 300, 200); //

      camera.position.set(944, 206, -262);
      camera.lookAt(new THREE.Vector3(0, 0, 0)); //()
      camera.updateProjectionMatrix();
      camera.updateMatrixWorld(true);

      var width = window.innerWidth; //
      var height = window.innerHeight; //
      var mat4 = new THREE.Matrix4();
      mat4.copy(camera.projectionMatrix);

      var mxArr = new Float32Array(mat4.elements);

      gl.uniformMatrix4fv(projectionMatrixLoc, false, mxArr);

      var mat4y = new THREE.Matrix4();

      mat4y.copy(camera.matrixWorldInverse);

      console.log(camera.matrixWorldInverse);
      var myArr = new Float32Array(mat4y.elements);

      gl.uniformMatrix4fv(modelViewMatrixLoc, false, myArr);

      var count = 0;
      var mouseX = 0,
        mouseY = 0;

      var windowHalfX = window.innerWidth / 2;
      var windowHalfY = window.innerHeight / 2;

      function draw() {
        camera.position.x += (mouseX - camera.position.x) * 0.01;

        camera.updateMatrixWorld(true);
        mat4y.copy(camera.matrixWorldInverse);

        var myArr = new Float32Array(mat4y.elements);
        gl.uniformMatrix4fv(modelViewMatrixLoc, false, myArr);

        var i = 0,
          j = 0;

        for (var ix = 0; ix < AMOUNTX; ix++) {
          for (var iy = 0; iy < AMOUNTY; iy++) {
            positions[i + 1] =
              Math.sin((ix + count) * 0.3) * 50 +
              Math.sin((iy + count) * 0.5) * 50;
            scales[j] =
              (Math.sin((ix + count) * 0.3) + 1.3) * 8 +
              (Math.sin((iy + count) * 0.5) + 1.3) * 8;
            i += 3;
            j++;
          }
        }
        count += 0.1;

        gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, scales, gl.STATIC_DRAW);

        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

        requestAnimationFrame(draw);

        gl.drawArrays(gl.POINTS, 0, 2500);
      }
      draw();

      function initShader(gl, vertexShaderSource, fragmentShaderSource) {
        var vertexShader = gl.createShader(gl.VERTEX_SHADER);
        var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(vertexShader, vertexShaderSource);
        gl.shaderSource(fragmentShader, fragmentShaderSource);
        gl.compileShader(vertexShader);
        gl.compileShader(fragmentShader);
        var program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        gl.useProgram(program);
        return program;
      }

      document.addEventListener("mousemove", onDocumentMouseMove, false);
      document.addEventListener("touchstart", onDocumentTouchStart, false);
      document.addEventListener("touchmove", onDocumentTouchMove, false);

      function onDocumentMouseMove(event) {
        mouseX = event.clientX - windowHalfX;
        mouseY = event.clientY - windowHalfY;
      }

      function onDocumentTouchStart(event) {
        if (event.touches.length === 1) {
          event.preventDefault();

          mouseX = event.touches[0].pageX - windowHalfX;
          mouseY = event.touches[0].pageY - windowHalfY;
        }
      }

      function onDocumentTouchMove(event) {
        if (event.touches.length === 1) {
          event.preventDefault();

          mouseX = event.touches[0].pageX - windowHalfX;
          mouseY = event.touches[0].pageY - windowHalfY;
        }
      }
    },
    // 开始气泡漂浮效果
    bubbleFloat: function () {
      var qipao = document.getElementsByClassName("qipao");
      var direction = -1;
      var step = -0.005;
      var count = 0;
      clearInterval(this.bubbleFloatTimer);
      this.bubbleFloatTimer = setInterval(() => {
        count++;
        if (count > 10) {
          direction = direction * -1;
          count = 0;
        }
        this.bubblesList.forEach((item, index) => {
          if (index % 2) {
            item.top = item.top + step * direction;
            // item.left = item.left + (step * direction)
          } else {
            item.top = item.top + step * direction * -1;
            // item.left = item.left + (step * direction * -1)
          }
        });
      }, 100);
    },
    // 获取环境数据
    onGetDustListData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
        seType: 8,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/dust/DustList`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.dustListData = res.data;
          that.echartsR1C1R1Init(); // 绘制 echarts-r1-c1-r1 (pm25) 折线图
          that.echartsR1C1R2Init(); // 绘制 echarts-r1-c1-r2 (噪音) 柱状图
          that.echartsR1C3R1Init(); // 绘制 echarts-r1-c3-r1 (pm10) 折线图
          that.echartsR1C3R2Init(); // 绘制 echarts-r1-c3-r2 (温度) 折线图
          that.echartsR2C1Init(); // 绘制 echarts-r2-c1 (风力) 柱状图
          that.echartsR2C4Init(); // 绘制 echarts-r2-c4 (湿度) 柱状图
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取报警数据
    onGetDustAlarmData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
        seType: 8,
        currentDateStr: this.currentDeviceAlarmDate,
        serialNumber: this.currentDeviceAlarm,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/dust/TotalAlarmBySerTypeAndSerNum`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.dustAlarmData = res.data.itemList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取报警种类数据
    onGetDustAlarmTypeData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;

      var data = {
        projectId: this.currentProject.id,
        seType: 8,
        currentDateStr: this.currentDeviceAlarmTypeDate,
        serialNumber: this.currentDeviceAlarmType,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/dust/ItemAlarmBySerTypeAndSerNum`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.dustAlarmTypeData = res.data.itemList;
          that.echartsR2C3Init(); // 绘制 echarts-r2-c3 种类报警信息饼图
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取日期字符串
    getDay: function (day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    // 日期时间格式化
    doHandleMonth: function (month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    // 绘制 echarts-r1-c1-r1 (pm25) 折线图
    echartsR1C1R1Init: function () {
      this.echarts.r1c1r1 = this.$echarts
        .init(document.getElementById("echarts-r1-c1-r1"))
        .dispose();
      this.echarts.r1c1r1 = this.$echarts.init(
        document.getElementById("echarts-r1-c1-r1")
      );
      var xData = this.currentPm25Data.time;
      var yData = this.currentPm25Data.data;
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 20,
          left: 20,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(255,225,255,.1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "line",
            smooth: false,
            lineStyle: {
              normal: {
                width: 2,
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.5)",
                    },
                  ],
                  globalCoord: false,
                },
                shadowColor: "rgba(249,165,137, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7,
              },
            },
            itemStyle: {
              normal: {
                color: "#F6D06F",
                borderWidth: 10,
                borderColor: "#F6D06F",
              },
            },
          },
          {
            data: yData,
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: 0,
              },
            },
          },
        ],
      };
      this.echarts.r1c1r1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c1r1.resize();
      });
    },
    // 绘制 echarts-r1-c1-r2 (噪音) 柱状图
    echartsR1C1R2Init: function () {
      this.echarts.r1c1r2 = this.$echarts
        .init(document.getElementById("echarts-r1-c1-r2"))
        .dispose();
      this.echarts.r1c1r2 = this.$echarts.init(
        document.getElementById("echarts-r1-c1-r2")
      );
      var xData = this.currentNoiseData.time;
      var yData = this.currentNoiseData.data;
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 20,
          left: 30,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            // rotate: 45,
            // formatter: function (value, index) {
            //   if (index % 2 != 0) {
            //     return "\n" + value;
            //   } else {
            //     return value;
            //   }
            // },
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(0, 228, 255, .1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(91, 221, 40, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(91, 221, 40, .2)", // 100% 处的颜色
                  },
                ],
              },
              // borderRadius: 50
            },
            itemStyle: {
              color: "rgba(91, 221, 40, .5)",
              borderRadius: [50, 50, 0, 0],
            },
          },
        ],
      };
      this.echarts.r1c1r2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c1r2.resize();
      });
    },
    // 绘制 echarts-r1-c3-r1 (pm10) 折线图
    echartsR1C3R1Init: function () {
      this.echarts.r1c3r1 = this.$echarts
        .init(document.getElementById("echarts-r1-c3-r1"))
        .dispose();
      this.echarts.r1c3r1 = this.$echarts.init(
        document.getElementById("echarts-r1-c3-r1")
      );
      var xData = this.currentPm10Data.time;
      var yData = this.currentPm10Data.data;
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 20,
          left: 20,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            // rotate: 45,
            // formatter: function (value, index) {
            //   if (index % 2 != 0) {
            //     return "\n" + value;
            //   } else {
            //     return value;
            //   }
            // },
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(255,225,255,.1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "line",
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(58, 160, 129, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(58, 160, 129, 0.5)",
                    },
                  ],
                  globalCoord: false,
                },
                shadowColor: "rgba(58, 160, 129, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7,
              },
            },
            itemStyle: {
              normal: {
                color: "#3a5ea0",
                borderWidth: 10,
                borderColor: "#3a5ea0",
              },
            },
          },
          {
            data: yData,
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(58, 160, 129, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(58, 160, 129, 0.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: 0,
              },
            },
          },
        ],
      };
      this.echarts.r1c3r1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c3r1.resize();
      });
    },
    // 绘制 echarts-r1-c3-r2 (温度) 折线图
    echartsR1C3R2Init: function () {
      this.echarts.r1c3r2 = this.$echarts
        .init(document.getElementById("echarts-r1-c3-r2"))
        .dispose();
      this.echarts.r1c3r2 = this.$echarts.init(
        document.getElementById("echarts-r1-c3-r2")
      );
      var xData = this.currentTemperatureData.time;
      var yData = this.currentTemperatureData.data;
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 20,
          left: 30,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            // rotate: 45,
            // formatter: function (value, index) {
            //   if (index % 2 != 0) {
            //     return "\n" + value;
            //   } else {
            //     return value;
            //   }
            // },
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(221, 40, 40, .1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(221, 40, 40, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(221, 40, 40, .2)", // 100% 处的颜色
                  },
                ],
              },
            },
            itemStyle: {
              color: "rgba(221, 40, 40, .5)",
              borderRadius: [50, 50, 0, 0],
            },
          },
        ],
      };
      this.echarts.r1c3r2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c3r2.resize();
      });
    },
    // 绘制 echarts-r2-c1 (风力) 柱状图
    echartsR2C1Init: function () {
      this.echarts.r2c1 = this.$echarts
        .init(document.getElementById("echarts-r2-c1"))
        .dispose();
      this.echarts.r2c1 = this.$echarts.init(
        document.getElementById("echarts-r2-c1")
      );
      var xData = this.currentWindData.time;
      var yData = this.currentWindData.data;
      var option = {
        grid: {
          top: 10,
          bottom: 30,
          left: 40,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        yAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#00e4ff",
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "value",
          axisTick: {
            // show: false,
          },
          axisLabel: {
            // show: false,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "rgba(224, 134, 31, .1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: {
                type: "linear",
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(224, 134, 31, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(224, 134, 31, .2)", // 100% 处的颜色
                  },
                ],
              },
            },
            itemStyle: {
              color: "rgba(224, 134, 31, .5)",
              borderRadius: [0, 50, 50, 0],
            },
          },
        ],
      };
      this.echarts.r2c1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r2c1.resize();
      });
    },
    // 绘制 echarts-r2-c3 种类报警信息饼图
    echartsR2C3Init: function () {
      this.echarts.r2c3 = this.$echarts
        .init(document.getElementById("echarts-r2-c3"))
        .dispose();
      this.echarts.r2c3 = this.$echarts.init(
        document.getElementById("echarts-r2-c3")
      );

      var listData = this.dustAlarmTypeData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.deviceName + item.alarmName,
          value: item.alarmNum,
        });
        legend.push(item.deviceName + item.alarmName);
      });
      var option = {
        legend: {
          data: legend,
          type: "scroll",
          orient: "vertical",
          bottom: 0,
          right: 0,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          pageTextStyle: {
            color: "#00e4ff",
          },
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.deviceName + item.alarmName) {
                arr =
                  item.deviceName +
                  " " +
                  item.alarmName +
                  " " +
                  item.alarmNum +
                  " 次";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "种类报警信息",
            type: "pie",
            center: ["35%", "50%"],
            radius: ["40%", "70%"],
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.r2c3.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r2c3.resize();
      });
    },
    // 绘制 echarts-r2-c4 (湿度) 柱状图
    echartsR2C4Init: function () {
      this.echarts.r2c4 = this.$echarts
        .init(document.getElementById("echarts-r2-c4"))
        .dispose();
      this.echarts.r2c4 = this.$echarts.init(
        document.getElementById("echarts-r2-c4")
      );
      var xData = this.currentWetData.time;
      var yData = this.currentWetData.data;
      var option = {
        grid: {
          top: 10,
          bottom: 30,
          left: 40,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        yAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#00e4ff",
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "value",
          axisTick: {
            // show: false,
          },
          axisLabel: {
            // show: false,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "rgba(115, 31, 224, .1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: {
                type: "linear",
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(115, 31, 224, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(115, 31, 224, .2)", // 100% 处的颜色
                  },
                ],
              },
            },
            itemStyle: {
              color: "rgba(115, 31, 224, .5)",
              borderRadius: [0, 50, 50, 0],
            },
          },
        ],
      };
      this.echarts.r2c4.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r2c4.resize();
      });
    },
    // 切换设备
    setDevice: function (index) {
      this.currentDevicePm25 = index;
      this.currentDevicePm10 = index;
      this.currentDeviceNoise = index;
      this.currentDeviceTemperature = index;
      this.currentDeviceWind = index;
      this.currentDeviceWet = index;
    },
    // 获取当前项目所有设备环境因子的平均值
    getDustAvgList: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
        seType: 8,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/dust/dustAvgList`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          // that.dustAvgList = res.data;
          that.bubblesList[0].value = res.data.pm25
          that.bubblesList[1].value = res.data.atm
          that.bubblesList[2].value = res.data.wd360
          that.bubblesList[3].value = res.data.wp
          that.bubblesList[4].value = res.data.pm10
          that.bubblesList[5].value = res.data.tsp
          that.bubblesList[6].value = res.data.noise
          that.bubblesList[7].value = res.data.hum
          that.bubblesList[8].value = res.data.tem
          that.bubblesList[9].value = res.data.ws
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>